


.partner-title{
    display: flex;
    justify-content: center;
    align-items: center;
}

.partner-title h1{
    font-family: var(--font-family);
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}