@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
.gpt3__blog-container_article {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    background: var(--color-footer);
}

.gpt3__blog-container_article-image {
    width: 100%;
    height: 100%;
    background: var(--color-bg);
}

.gpt3__blog-container_article-image img {
    width: 100%;
    height: 100%;
}

.gpt3__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    height: 100%;
}

.gpt3__blog-container_article-content p {
    font-family: var(--font-family);
    font-size: 11.65px;
    font-weight: 700;
    line-height: 35px;

    color: #fff;
}

.gpt3__blog-container_article-content h3 {
    font-family: var(--font-family);
    font-size: 25.11px;
    font-weight: 800;
    line-height: 30.30px;
    
    color: #fff;
    margin-bottom: 5rem;
    cursor: pointer;
}

.gpt3__blog-container_article-content p:last-child {
    cursor: pointer;
}


@media screen and (max-width: 550px) {
    .gpt3__blog-container_article-content h3 {
        font-size: 18px;
        line-height: 25p;
    }
}
.gpt3__blog {
    display: flex;
    flex-direction: column;    
}

.gpt3__blog-heading {
    width: 100%;
    text-align: left;

    margin-bottom: 5rem;
}

.gpt3__blog-heading h1 {
    font-size: 62px;
    line-height: 75px;
    font-family: var(--font-family);
    font-weight: 800;
}

.gpt3__blog-container {
    display: flex;
    flex-direction: row;
}

.gpt3__blog-container_groupA {
    flex: 0.75 1;
    margin-right: 2rem;
}

.gpt3__blog-container_groupB {
    flex: 1 1;

    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}

.gpt3__blog-container_groupA .gpt3__blog-container_article-image {
    height: auto;
}

@media screen and (max-width: 990px) {
    .gpt3__blog-container {
        flex-direction: column-reverse;
    }


    .gpt3__blog-container_groupA {
        margin: 2rem 0;
    }

    .gpt3__blog-container_groupA .gpt3__blog-container_article  {
       width: 48%;
    }

    
}

@media screen and (max-width: 700px) {
    .gpt3__blog-container_groupB {
        grid-template-columns: repeat(1, 1fr);
    }

    .gpt3__blog-container_groupA .gpt3__blog-container_article  {
       width: 100%;
    }

    .gpt3__blog-heading h1 {
        font-size: 46px;
        line-height: 52px;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__blog-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

}
.gpt3__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    margin: 1rem;
}

.gpt3__features-container__feature-title {
    flex: 1 1;
    max-width: 180px;
    margin-right: 2rem;
}

.gpt3__features-container__feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #fff;
}

.gpt3__features-container__feature-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.gpt3__features-container_feature-text {
    flex: 2 1;
    max-width: 390px;
    display: flex;
}

.gpt3__features-container_feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
}

@media screen and (max-width: 550px) {
    .gpt3__features-container__feature-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .gpt3__features-container_feature-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .gpt3__features-container__feature {
        margin: 1rem 0;
    }
}
.gpt3__whatgpt3 {
    display: flex;
    flex-direction: column;

    padding: 2rem;
    background: var(--color-footer);

    /* ff 3.6+ */

    /* safari 5.1+,chrome 10+ */

    /* opera 11.10+ */

    /* ie 10+ */

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.gpt3__whatgpt3-feature {
    display: flex;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-feature .gpt3__features-container__feature {
    margin: 0;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
    max-width: 700px;
}

.gpt3__whatgpt3-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    margin: 4rem 0 2rem;
}

.gpt3__whatgpt3-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}

.gpt3__whatgpt3-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
}

.gpt3__whatgpt3-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: 2rem;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-container .gpt3__features-container__feature {
    flex: 1 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-container .gpt3__features-container_feature-text {
    margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
    .gpt3__whatgpt3-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .gpt3__whatgpt3-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .gpt3__whatgpt3-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-container .gpt3__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}
.gpt3__features {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.gpt3__features-heading {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
    margin-right: 5rem;
}

.gpt3__features-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}

.gpt3__features-heading p {
    color: var(--color-subtext);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);

    margin-top: 2rem;
}

.gpt3__features-container {
    flex: 1.5 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 990px) {
    .gpt3__features {
        flex-direction: column;
    }

    .gpt3__features-heading {
        margin: 0 0 2rem 0;
    }
}


@media screen and (max-width: 550px) {
    .gpt3__features-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }
}
.gpt3__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--color-footer);
}

.gpt3__footer-heading {
    width: 100%;
    text-align: center;

    margin-bottom: 3rem;
}

.gpt3__footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 64px;
    line-height: 75px;
}

.gpt3__footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    border: 1px solid #fff;
    text-align: center;

    margin-bottom: 10rem;
    cursor: pointer;
}

.gpt3__footer-btn p {
    font-size: 18px;
    font-family: var(--font-family);
    line-height: 21px;
    color: #fff;
    word-spacing: 2px;
}

.gpt3__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    text-align: left;
}

.gpt3__footer-links div {
    width: 250px;
    margin: 1rem;
}

.gpt3__footer-links_logo {
    display: flex;
    flex-direction: column;
}

.gpt3__footer-links_logo img {
    width: 118px;
    height: auto;

    margin-bottom: 1rem;
}

.gpt3__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
    color: #fff;
}

.gpt3__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.gpt3__footer-links_div h4 {
    font-size: 14px;
    line-height: 17px;
    font-family: var(--font-family);
    color: #fff;

    margin-bottom: 0.9rem;
}

.gpt3__footer-links_div p {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;

    margin: 0.5rem 0;
    cursor: pointer;
}

.gpt3__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.gpt3__footer-copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #fff;
}

@media screen and (max-width: 850px) {
    .gpt3__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .gpt3__footer-links div {
        margin: 1rem 0;
    }

    .gpt3__footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .gpt3__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}

.gpt3__header {
    display: flex;
    flex-direction: row;
}

.gpt3__header-content {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.gpt3__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.gpt3__header-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.gpt3__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;

    display: flex;
    flex-direction: row;
}

.gpt3__header-content__input input {
    flex: 2 1;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.gpt3__header-content__input button {
    flex: 0.6 1;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #FF4820;
    border: 2px solid #FF4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.gpt3__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
}

.gpt3__header-content__people img {
    width: 181.79px;
    height: 38px;
}

.gpt3__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.gpt3__header-image {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gpt3__header-image img {
    width: 100%;
    height: auto;
}

@media screen and (max-width: 1050px) {
    .gpt3__header {
        flex-direction: column;
    }

    .gpt3__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .gpt3__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .gpt3__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .gpt3__header-content__people {
        flex-direction: column;
    }

    .gpt3__header-content__people p {
        margin: 0;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .gpt3__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .gpt3__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}
.gpt3__possibility {
    display: flex;
    flex-direction: row;
}

.gpt3__possibility-image {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;
}

.gpt3__possibility-image img {
    width: 100%;
    height: auto;
}

.gpt3__possibility-content {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
}

.gpt3__possibility-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71E5FF;
}

.gpt3__possibility-content h4:last-child {
    color: #ff8A71;
    cursor: pointer;
}

.gpt3__possibility-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin: 1rem 0;
}

.gpt3__possibility-content p {
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

    margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
    .gpt3__possibility {
        flex-direction: column;
    }

    .gpt3__possibility-image {
        margin: 1rem 0;
    }

    .gpt3__possibility-image img {
        width: unset;
        height: unset;
    }

    .gpt3__possibility-content {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__possibility-image img {
        width: 100%;
        height: 100%;
    }
}


.gpt3__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.gpt3__brand div {
    flex: 1 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
}



.partner-title{
    display: flex;
    justify-content: center;
    align-items: center;
}

.partner-title h1{
    font-family: var(--font-family);
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}
.gpt3__cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar)
}

.gpt3__cta-content {
    display: flex;
    flex-direction: column;

    text-align: left;
    color: #fff;
}

.gpt3__cta-content p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
}

.gpt3__cta-content h3 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 45px;
    font-weight: 800;
}

.gpt3__cta-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 2rem;
}

.gpt3__cta-btn button {
    background: #000;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    border-radius: 2rem;

    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
}

@media screen and (max-width: 650px) {
    .gpt3__cta {
        flex-direction: column;
    }

    .gpt3__cta-btn {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__cta {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .gpt3__cta-content h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .gpt3__cta-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}

.gpt3__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}

.gpt3__navbar-links {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.gpt3__navbar-links_logo {
    margin-right: 2rem;
}

.gpt3__navbar-links_logo img {
    width: 62.56px;
    height: auto;
}

.gpt3__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.gpt3__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #FF4820;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

.gpt3__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.gpt3__navbar-menu svg {
    cursor: pointer;
}

.gpt3__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.gpt3__navbar-menu_container p {
    margin: 1rem 0;
}

.gpt3__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
    .gpt3__navbar-links_container {
        display: none;
    }

    .gpt3__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__navbar {
        padding: 2rem;
    }

    .gpt3__navbar-sign {
        display: none;
    }

    .gpt3__navbar-menu_container {
        top: 20px;
    }

    .gpt3__navbar-menu_container-links-sign {
        display: block;
    }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg {

  /* safari 5.1+,chrome 10+ */

  /* opera 11.10+ */

  /* ie 10+ */

  /* global 92%+ browsers support */
  background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }  
}
:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #326bed 1.84%, #209f2b 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #0d922e -13.86%, #0fe4e4 99.55%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}

